import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";

export default function ExamFinishModal({
  finalResult,
  handleCloseModal,
  openModal,
}) {
  return (
    <React.Fragment>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle
          style={{
            fontWeight: "bold",
            padding: "14px 50px",
            backgroundColor: "#52668C",
            color: "white",
          }}
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          Your practice test result
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography>{finalResult}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
