import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ContactInformation from "../container/ContactInformation/ContactInformation";
import ContactInformationTwo from "../container/ContactInformation/ContactInformationTwo.js";
import ContactInformationThree from "../container/ContactInformation/ContactInformationThree.js";
import GoogleMap from "../container/Map/GoogleMap";
import ContactFromContainer from "../container/ContactFromContainer/ContactFromContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import NavScrollTop from "../components/NavScrollTop.jsx";

const Contact = () => {
  return (
    <React.Fragment>
      <SEO title="xCel || Contact" />
      <NavScrollTop>
        <Header />
        <Breadcrumb
          image="images/bg/xcelPattern2.png"
          title="Get in Touch: Contact Us"
          content="Home"
          contentTwo="Contact Us"
          subTitle="Our team is ready to hear from you"
          backgroundProp="cover"
        />
        {/* <ContactInformation /> */}
        <ContactInformationTwo />
        {/* <ContactInformationThree /> */}
        <GoogleMap />
        {/* <ContactFromContainer /> */}
        <Footer />
      </NavScrollTop>
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Contact;
