import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({
  title,
  content,
  contentTwo,
  image,
  subTitle,
  backgroundProp,
}) => {
  const publicUrl = process.env.PUBLIC_URL;
  return (
    <div
      className="page-title-section section section-padding-top overlay-two"
      style={{
        backgroundImage: `url(${publicUrl}/${image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: backgroundProp ? "cover" : "contain",
        backgroundPosition: "center center",
      }}
    >
      <div className="page-title">
        <div className="container" style={{ paddingTop: "40px" }}>
          <p className="title" style={{ fontSize: "3rem" }}>
            {title}
          </p>
          <p
            className="sub-title"
            style={{
              fontSize: "25px",
              textAlign: "center",
              color: "white",
            }}
          >
            {subTitle}
          </p>
        </div>
      </div>
      <div className="page-breadcrumb position-static">
        <div className="container">
          <ul
            className="breadcrumb justify-content-center"
            style={{ paddingTop: "10px" }}
          >
            <li>
              <Link to={process.env.PUBLIC_URL + "/"}>{content}</Link>
            </li>
            <li className="current">{contentTwo}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  contentTwo: PropTypes.string,
  image: PropTypes.string,
};

export default Breadcrumb;
