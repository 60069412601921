import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { envData } from "../../envs";
import { isMobile } from "react-device-detect";
import { columns } from "../Instructor/data/StudentTableColumnList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { fakeAuthProvider } from "../../LoginActions";
import Alert from "@mui/material/Alert";
import { CSVLink, CSVDownload } from "react-csv";
import IosShareIcon from "@mui/icons-material/IosShare";
import StudentUploadPage from "../Instructor/StudentUploadPage";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#001F3F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Spinner = () => (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
    <p>Loading...</p>
  </div>
);

const GeneralStudentTable = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [schoolFilter, setSchoolFilter] = useState("");
  const [registeredFilter, setRegisteredFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  function containsNumber(str) {
    return /\d/.test(str);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    // Fetch student data with axios
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        const response = await axios.get(`${envData.baseApiUrl}/v1/students`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const numericFilteredStudents = response.data.filter(
          (student) => !containsNumber(student.firstName + student.lastName)
        );
        const filteredStudents = numericFilteredStudents.filter(
          (student) =>
            student.firstName !== "asd" &&
            student.firstName !== "Asd" &&
            student.firstName !== "ASD"
        );

        setStudents(filteredStudents);
      } catch (error) {
        console.error("Error fetching student data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Filtered student data based on search and filter criteria
  const filteredStudents = students.filter((student) => {
    const matchesName = student?.firstName
      ?.toLowerCase()
      .includes(searchName.toLowerCase());
    const matchesCountry = countryFilter
      ? student.countryName === countryFilter
      : true;
    const matchesSchool = schoolFilter
      ? student.schoolName === schoolFilter
      : true;
    const matchesRegistered =
      registeredFilter === "registered"
        ? student?.exams &&
          student.exams.map((exam) => exam.examName).join(", ")
        : registeredFilter === "NA"
        ? student?.exams &&
          !student.exams.map((exam) => exam.examName).join(", ")
        : true;
    return matchesName && matchesCountry && matchesSchool && matchesRegistered;
  });

  // Unique countries and schools for filter options
  const uniqueCountries = [
    ...new Set(students.map((student) => student.countryName)),
  ];
  const uniqueSchools = [
    ...new Set(students.map((student) => student.schoolName)),
  ];
  const userDataType =
    fakeAuthProvider.getData().type === "A" ||
    fakeAuthProvider.getData().type === "P";
  return (
    <div
      style={{
        marginBottom: "10px",
        paddingBottom: "12px",
      }}
    >
      {userDataType && (
        <StudentUploadPage userType={fakeAuthProvider.getData().type} />
      )}
      <div
        style={{
          margin: "10px auto",
          width: "90%",
        }}
      >
        <Box
          style={{
            display: "flex",
            marginTop: "5px",
            justifyContent: "space-between",
          }}
        >
          <input
            style={{ width: isMobile ? "90%" : "50%" }}
            type="text"
            placeholder="Search by name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />

          <Box style={{ marginLeft: "15px", textAlign: "center" }}>
            <CSVLink data={filteredStudents}>
              <IosShareIcon style={{ fontSize: "25px", color: "#1292EE" }} />
              <br />
              Export to file
            </CSVLink>
          </Box>
        </Box>

        {/* Search and Filter Inputs */}

        <br />

        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Country
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={countryFilter}
            onChange={(e) => setCountryFilter(e.target.value)}
            label="Country"
          >
            <MenuItem value="">
              <em>All countries</em>
            </MenuItem>
            {uniqueCountries.map((countryName) => (
              <MenuItem key={countryName} value={countryName}>
                {countryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">School</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={schoolFilter}
            onChange={(e) => setSchoolFilter(e.target.value)}
            label="School"
          >
            <MenuItem value="">
              <em>All schools</em>
            </MenuItem>
            {uniqueSchools.map((schoolName) => (
              <MenuItem key={schoolName} value={schoolName}>
                {schoolName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
          <Select
            value={registeredFilter}
            onChange={(e) => setRegisteredFilter(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="registered">Registered subjects</MenuItem>
            <MenuItem value="NA">Not Registered</MenuItem>
          </Select>
        </FormControl>
        {!filteredStudents.length && (
          <div>
            <Alert severity="info">
              There is no registered students to show at this time.
            </Alert>
          </div>
        )}
        {/* Spinner while loading */}
        {loading ? (
          <Spinner />
        ) : (
          <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "5px" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead style={{ border: "3px solid red" }}>
                  <TableRow style={{ border: "3px solid red" }}>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                          borderBottom: "2px solid grey",
                        }}
                      >
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!filteredStudents.length &&
                    filteredStudents
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, ind) => {
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.email}
                          >
                            <StyledTableCell>{ind + 1}</StyledTableCell>
                            <StyledTableCell>
                              {row.firstName + " " + row.lastName}
                            </StyledTableCell>

                            <StyledTableCell>{row.countryName}</StyledTableCell>
                            <StyledTableCell>{row.email}</StyledTableCell>

                            <StyledTableCell align="right">
                              {row.grade}
                            </StyledTableCell>

                            <StyledTableCell align="right">
                              {row.schoolName}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.dob}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.phone}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.exams.length
                                ? row.exams
                                    .map((exam) => exam.examName)
                                    .join(", ")
                                : "NA"}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredStudents.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </div>
    </div>
  );
};

export default GeneralStudentTable;
