import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { columns } from "./data/StudentTableColumnList";
import FilterListIcon from "@mui/icons-material/FilterList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#001F3F",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function StudentTablePage({
  filtered,
  studentData,
  openSchoolListForFiltering,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleSchoolFilter = () => {
    openSchoolListForFiltering(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredSearchResult = () => {
    return studentData.filter((student) =>
      student?.firstName?.toLowerCase().includes(filtered.toLowerCase())
    );
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "5px" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ border: "3px solid red" }}>
              <TableRow style={{ border: "3px solid red" }}>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                      borderBottom: "2px solid grey",
                    }}
                  >
                    {column.label}
                    {column.id === "school" && (
                      <FilterListIcon
                        onClick={handleSchoolFilter}
                        style={{
                          marginLeft: "5px",
                          fontSize: "30px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSearchResult()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, ind) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.email}
                    >
                      <StyledTableCell>{ind + 1}</StyledTableCell>
                      <StyledTableCell>
                        {row.firstName + " " + row.lastName}
                      </StyledTableCell>

                      <StyledTableCell>{row.countryName}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>

                      <StyledTableCell align="right">
                        {row.grade}
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        {row.schoolName}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.dob}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.exams.length
                          ? row.exams.map((exam) => exam.examName).join(", ")
                          : "NA"}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredSearchResult().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
