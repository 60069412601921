import { useState, useEffect } from "react";
import axios from "axios";
import { envData } from "../../envs";
import MathProctorTestComponent from "../ProctorComponents/proctor";
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CircularProgress from "@mui/material/CircularProgress";
import "./math.css";
import { isMobile } from "react-device-detect";
function MathComponent() {
  const [startMathTest, setStartMathTest] = useState(false);
  const [studentMetaData, setStudentData] = useState([]);

  const handleMathStartTest = () => setStartMathTest(!startMathTest);
  const getStudentMetaData = async () => {
    const token = localStorage.getItem("token");
    try {
      const req = await axios.get(`${envData.baseApiUrl}/v1/students`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStudentData(req.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getStudentMetaData();
  }, []);
  return (
    <div
      style={{
        // height: "100%",
        display: "flex",
        overflowY: "hidden",
        overflowX: "auto",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!startMathTest && (
        <>
          <h5 style={{ padding: "10px", textAlign: "center" }}>
            Here’s everything you need to know to get started
          </h5>
          <div
            style={{ marginTop: "20px", width: isMobile ? "90%" : "60%" }}
            className="scrollable-list"
          >
            <ol>
              <li>
                <strong>
                  {" "}
                  Please remember to turn on your camera, microphone, and screen
                  share for the duration of the test.
                </strong>
              </li>
              <li>
                <strong>Start Time:</strong> Please wait for the Proctor’s
                signal before opening your exam booklet. You’ll be notified when
                it’s time to begin.
              </li>
              <li>
                {" "}
                <strong>Timing Your Test:</strong> You have 80 minutes to
                complete the exam. Keep track of your time to ensure you can
                answer all questions.{" "}
              </li>
              <li>
                <strong>Sections Overview:</strong>
                <ul>
                  <li>
                    Part 1: Questions 1-20. Each question is worth 2 points. No
                    points are lost for questions you leave unanswered, and no
                    points will be deducted for incorrect answers.
                  </li>
                  <li>
                    Part 2: Questions 21-25. Each question is worth 3 points.
                    There are no penalties for incorrect or unanswered questions
                    in this part.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Answer Sheet Instructions: </strong>Use a 2B pencil to
                mark your answers clearly on the Answer Sheet. Make sure your
                marks are inside the boxes and are easy to read.
              </li>

              <li>
                <strong>Show Your Work:</strong> Write out your calculations and
                thought process clearly in the spaces provided. This will help
                us see how you arrived at your answers.
              </li>
              <li>
                <strong>Minimum Time Requirement:</strong> You must stay in the
                exam room for at least 45 minutes to ensure everyone has a fair
                chance to complete their test.
              </li>
              <li>
                <strong>No Electronics:</strong> Please leave all electronic
                devices, including calculators, phones, and tablets, out of the
                exam room. We want you to rely on your unique abilities and
                skills!
              </li>
              <li>
                <strong>Fill in Your Details:</strong> Don’t forget to write
                your Name and School on both the Answer Sheet and the Contest
                Booklet. This helps us identify your work.
              </li>
              <li>
                <strong>Work Independently:</strong> Do not ask for or give help
                during the exam.{" "}
                <strong>
                  Any form of cheating will be noticed, and it could impact your
                  results.
                </strong>
              </li>
              <li>
                <strong>Keep Materials in the Room:</strong> All exam papers and
                notes must stay in the exam room. You cannot take any materials
                with you when you leave.
              </li>
              <li>
                <strong>Be Respectful:</strong> Please be considerate to
                everyone around you. Disruptive behavior is not allowed and can
                affect your participation.
              </li>
              <h6>Good luck, and enjoy solving the math challenges!</h6>
            </ol>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                margin: "10px",
              }}
            >
              <Button
                // style={{ marginBottom: "30px" }}
                variant="contained"
                onClick={handleMathStartTest}
              >
                <PlayArrowIcon style={{ margin: "0 7px" }} />
                start math test
              </Button>
            </div>
          </div>
        </>
      )}
      {startMathTest && (
        <MathProctorTestComponent studentMetaData={studentMetaData} />
      )}
    </div>
  );
}

export default MathComponent;
