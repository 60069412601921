import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

const ArtContainer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  //   const cate = data.categories.map((value, i) => {
  //     return (
  //       <span className="d-inline" key={i}>
  //         {value}
  //         {i !== data.categories.length - 1 && " , "}
  //       </span>
  //     );
  //   });
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                <h2 className="title">
                  Exam
                  <br /> Details
                </h2>
                <div className="work-details-list mt-12">
                  <div className="details-list">
                    <img
                      src="images/categoryIcons/date.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Date</label>
                    <span style={{ textAlign: "center" }}>June/2025</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/grade.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Grades</label>
                    <span style={{ paddingLeft: "45px" }}>0-12</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/location.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Location</label>
                    <span style={{ textAlign: "center" }}>UCLA, CA</span>
                  </div>

                  {/* <div className="details-list">
                    <label>Awards</label>
                    <span>asdasdasd</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h3 className="title" data-aos="fade-up">
                    Partnership XCEL ( UCLA) & WE ARE THE WORLD
                  </h3>

                  <div className="desc mt-8">
                    <ol>
                      <li>
                        {" "}
                        Theme 2025: Anime, Manga and your favorite cartoon
                        characters. Last year this topic has already been
                        announced at our exhibitions and has received a wide
                        response among students of art schools and art studios
                        of different countries. Now this topic is very popular
                        among children and teenagers.{" "}
                      </li>
                      {/* <li>
                        Format: International exhibition-competition and award
                        ceremony (or somehow we can call it all international
                        art examination board XCEL & WE ARE THE WORLD in UCLA
                        University?)
                      </li> */}
                      <li>
                        ⁠Format: registration of drawings on XCEL web platform
                      </li>
                      <li>
                        2 rounds with the final in Los Angeles on June, 2025 at
                        the University of UCLA, CA
                      </li>
                      <li>
                        Materials: markers, colored pencils, watercolor,
                        gouache, acrylic, oil, felt-tip pens, ink or digital
                        drawings on computer.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="custom-column-thumbnail mt-lg-14 mt-1"
              data-aos="fade-up"
            >
              {/* <h5>Preliminary Round:</h5> */}
              <ul>
                <li>
                  ⁠International jury, which will include 10-15 people: artists,
                  designers, university teachers, directors of art schools, who
                  will select drawings in 3 age categories: from
                  <ul>
                    <li>5-9 years</li>
                    <li>10-13 years</li>
                    <li>14-17 years</li>
                  </ul>
                </li>
                <li>
                  ⁠Selection criteria: compliance with the theme, accuracy,
                  composition, originality, drawing technique. 8. ⁠ 350 works
                  will advance to the finals, which will be selected in the 1st
                  round by artists and art teachers from different countries.
                  The finals will be held at UCLA in June 2024.
                </li>
                <li>
                  Registration: works are accepted on XCEL web platform. To
                  register, fill out a form and upload a drawing in good quality
                  in the format. jpg or . pdf, and pay registration fee.
                </li>
                <li>
                  After registration, the participant&apos;s/supervisors/parents
                  will receive a notification that the registration was
                  successful and the drawing has been sent to the working
                  commission. Within a month or more. The commission will select
                  the 350 best works which will participate in the final round.
                </li>
                <li>
                  Once the commission&apos;s work is completed, the participant
                  will receive an email notifying whether the drawing has
                  qualified for the final round.
                </li>
                <li>
                  If the drawing is selected, parents or the school will receive
                  an invitation to the final exhibition at UCLA and detailed
                  information about the event in Los Angeles.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ArtContainer.propTypes = {
  data: PropTypes.object,
};

export default ArtContainer;
