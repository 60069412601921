import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

const WorkDetailsContainer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  //   const cate = data.categories.map((value, i) => {
  //     return (
  //       <span className="d-inline" key={i}>
  //         {value}
  //         {i !== data.categories.length - 1 && " , "}
  //       </span>
  //     );
  //   });
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                <h2 className="title">
                  Exam
                  <br /> Details
                </h2>
                <div className="work-details-list mt-12">
                  <div className="details-list">
                    <img
                      src="images/categoryIcons/date.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Date</label>
                    <span style={{ textAlign: "center" }}>June/2025</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/grade.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Grades</label>
                    <span style={{ paddingLeft: "45px" }}>3-12</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/location.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Location</label>
                    <span style={{ textAlign: "center" }}>UCLA, CA</span>
                  </div>

                  {/* <div className="details-list">
                    <label>Awards</label>
                    <span>asdasdasd</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h3 className="title" data-aos="fade-up">
                    CogATxCel
                  </h3>

                  <div className="desc mt-8">
                    <p>
                      The CogATXCEL category focuses on challenging
                      students&apos; cognitive abilities through various types
                      of questions and tasks.
                    </p>
                    <ul>
                      <li>
                        The CogAT category assesses students&apos; cognitive
                        abilities, including verbal, quantitative, and nonverbal
                        reasoning.
                      </li>
                      <li>
                        Tasks and questions are designed to challenge
                        students&apos; critical thinking, problem-solving, and
                        logical reasoning skills.
                      </li>
                    </ul>

                    <h5>Competition Rounds:</h5>
                    <h6>Preliminary Round:</h6>
                    <ul>
                      <li>
                        The preliminary round consists of online assessments
                        accessible to students in grades 3-12.
                      </li>
                      <li>
                        Questions are tailored to each grade level to ensure
                        appropriateness and fairness.
                      </li>
                      <li>
                        Tasks may include verbal analogies, number series,
                        figure classification, and other cognitive reasoning
                        exercises.
                      </li>
                      <li>
                        Participants compete against their peers within their
                        respective grade levels.
                      </li>
                    </ul>

                    {/* {data.body.map((value, i) => {
                                            return(
                                                <div key={i} className="content mb-5" data-aos="fade-up" dangerouslySetInnerHTML={{__html: value}} />
                                            )
                                        })}

                                        <div className="work-btn">
                                            <Link className="btn btn-primary btn-hover-secondary" to={process.env.PUBLIC_URL + data.btn.link}>{data.btn.text}</Link>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="custom-column-thumbnail mt-lg-14 mt-1"
              data-aos="fade-up"
            >
              <h5>Global Finals:</h5>
              <ul>
                <li>
                  Qualifying participants from the preliminary round advance to
                  the global finals held in the USA.
                </li>
                <li>
                  The global finals feature more advanced and challenging tasks
                  to further test participants&apos; cognitive abilities.
                </li>
                <li>
                  Participants compete for gold, silver, and bronze medals based
                  on their performance in the finals.
                </li>
              </ul>
              <h5>Assessment Criteria:</h5>
              <ul>
                <li>
                  Verbal Reasoning: Assessing vocabulary, verbal analogies, and
                  verbal classification skills. English language barriers may
                  occur.
                </li>
                <li>
                  Quantitative Reasoning: Evaluating numerical reasoning, number
                  series, and mathematical problem-solving abilities.
                </li>
                <li>
                  Nonverbal Reasoning: Testing spatial visualization, figure
                  classification, and pattern recognition skills.
                </li>
              </ul>
              <h5>Scoring and Evaluation:</h5>
              <ul>
                <li>
                  Participants&apos; performance is assessed based on the
                  accuracy and efficiency of their responses to the CogAT tasks.
                </li>
                <li>
                  Scoring criteria may vary based on the complexity and
                  difficulty of the questions. Or Standard True/False scoring.
                </li>
                <li>
                  Top performers in each grade level receive gold, silver, and
                  bronze medals, while all participants receive participation
                  certificates.
                </li>
              </ul>
              <h5>Closing Ceremony:</h5>
              <ul>
                <li>
                  The closing ceremony celebrates the achievements of
                  participants in the CogAT category, along with those in other
                  competition categories.
                </li>
                <li>
                  Awards are presented to the medalists, and certificates are
                  distributed to all participants.
                </li>
                <li>
                  The ceremony may include speeches, performances, and other
                  celebratory activities to acknowledge participants&apos; hard
                  work and dedication.
                </li>
              </ul>
              <h3>CogAT Category by Grade Levels:</h3>
              <ul>
                <li>Grade 3-4</li>
                <li>Grade 5-6</li>
                <li>Grade 7-8</li>
                <li>Grade 9-10</li>
                <li>Grade 11-12</li>
              </ul>
              <h5>Assessment Focus by Grade Levels:</h5>
              <ul>
                <li>
                  Grade 3-4: Introduction to more complex verbal and
                  quantitative reasoning tasks, including analogies, number
                  patterns, and figure matrices.
                </li>
                <li>
                  Grade 5-6: Advanced verbal and nonverbal reasoning exercises,
                  incorporating higher-level vocabulary, more intricate number
                  series, and abstract spatial reasoning.
                </li>
                <li>
                  Grade 7-8: Challenging tasks focusing on advanced verbal
                  analogies, mathematical problem-solving, and abstract
                  reasoning.
                </li>
                <li>
                  Grade 9-10: Rigorous assessments covering complex verbal and
                  quantitative reasoning, including critical thinking, deductive
                  reasoning, and advanced mathematical concepts.
                </li>
                <li>
                  Grade 11-12: Comprehensive evaluation of advanced cognitive
                  abilities, including advanced verbal reasoning, abstract
                  thinking, and complex problem-solving skills.
                </li>
              </ul>
              <h5>Scoring and Evaluation:</h5>
              <ul>
                <li>
                  Scoring criteria are adjusted to account for the developmental
                  stages and academic expectations of students at each grade
                  level.
                </li>
                <li>
                  Performance is evaluated based on the accuracy, speed, and
                  efficiency of responses to CogAT tasks within each grade
                  category.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WorkDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default WorkDetailsContainer;
