import img1 from "./dashImages/1.jpg";
import img2 from "./dashImages/instructor2.jpg";

export const dashImages = [
  {
    id: 1,
    logo: img1,
  },
  {
    id: 2,
    logo: img2,
  },
];
