import * as React from "react";
import Box from "@mui/material/Box";

import AspectRatio from "@mui/joy/AspectRatio";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";

export default function ExamResult() {
  return (
    <Box
      style={{
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        size="lg"
        variant="plain"
        orientation="horizontal"
        sx={{
          textAlign: "center",
          maxWidth: "100%",
          width: 500,
          // to make the demo resizable
          resize: "horizontal",
          overflow: "auto",
        }}
      >
        {/* <CardOverflow
          variant="solid"
          color="primary"
          sx={{
            flex: "0 0 200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            px: "var(--Card-padding)",
          }}
        >
          <Typography fontSize="xl4" fontWeight="xl" textColor="#fff">
            89
          </Typography>
          <Typography textColor="primary.200">
            Your Math score: Local
          </Typography>
        </CardOverflow> */}
        <CardContent sx={{ gap: 1.5, minWidth: 200 }}>
          <AspectRatio ratio="19/8" objectFit="contain" variant="plain">
            <img
              alt=""
              src="https://static.vecteezy.com/system/resources/previews/006/409/485/original/people-thinking-to-make-decision-problem-solving-and-find-creative-ideas-with-question-mark-in-flat-cartoon-background-for-poster-illustration-vector.jpg"
            />
          </AspectRatio>
          <CardContent>
            <Typography level="title-lg">Important numbers</Typography>
            <Typography fontSize="sm" sx={{ mt: 0.5 }}>
              {/* 43 Correct.
              <br />
              57 Incorrect */}
              No results to show
            </Typography>
          </CardContent>
          <Button
            disabled={true}
            variant="outlined"
            color="primary"
            sx={{
              "--variant-borderWidth": "2px",
              borderRadius: 40,
              borderColor: "primary.500",
              mx: "auto",
            }}
          >
            Download Result
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}
