import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import PageBlog from "../container/BlogGrid/PageBlog";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import IntroTwo from "../container/IntroSlider/IntroTwo.js";
import { isMobile } from "react-device-detect";
import NavScrollTop from "../components/NavScrollTop.jsx";

const BlogGrid = () => {
  return (
    <React.Fragment>
      <NavScrollTop>
        <SEO title="Xcel || Resources" />
        <Header />
        <Breadcrumb
          image="images/bg/xcelpattern3.png"
          title="Find out useful links"
          content="Home"
          contentTwo="Resources"
          backgroundProp="cover"
        />
        <PageBlog />
        {isMobile ? <CallToAction /> : <IntroTwo />}
        <Footer />
        <ScrollToTop />
      </NavScrollTop>
    </React.Fragment>
  );
};

export default BlogGrid;
