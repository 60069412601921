import React from "react";
import Accordion, {
  AccordionItem,
  AccordionTitle,
  AccordionContent,
} from "../Accordion";

const AccordionWrap = () => {
  return (
    <div className="agency-accordion max-mb-n30">
      <Accordion>
        <AccordionItem id="one">
          <AccordionTitle id="one">
            Welcome to the international XCEL competitions—an exhilarating
            journey that will challenge your minds, test your skills, and ignite
            your passion for science, technology, engineering, robotics, and
            mathematics. This isn&apos;t just about winning awards; it’s about
            pushing your potential and embracing innovation. Each of you has the
            power to make a significant impact on the world. Whether you’re
            designing groundbreaking technology, exploring the universe&apos;s
            mysteries, or solving complex problems, your contributions matter.
            <br /> <br />
            Here’s some inspiration as you embark on this journey: Believe in
            yourselves—confidence is key. Embrace challenges as opportunities to
            grow and innovate. Collaborate with peers, share ideas, and learn
            from each other. Stay curious, keep asking questions, and seek new
            knowledge. Persevere through setbacks—hard work pays off.
            Ultimately, XCEL is about making a difference. Your innovations can
            solve problems, improve lives, and shape the future. <br /> <br />{" "}
            This competition celebrates your dedication and passion for building
            future and exploration. It&apos;s your chance to showcase talents
            globally and be inspired by fellow enthusiasts. Remember, you’re
            already all winners for daring to dream and strive for greatness.
            <br />
            <br />
            Believe in your potential, embrace the journey, and let your passion
            for engagement in future building illuminate the path forward. The
            future awaits your creativity and dedication. Good luck, and may
            your efforts lead to remarkable discoveries! With admiration and
            encouragement, Michael Ulan Genialovich Dakeev President XCEL
            International Competitions
          </AccordionTitle>
        </AccordionItem>
        <AccordionItem id="two">
          <AccordionTitle id="two">About Michael Ulan Dakeev</AccordionTitle>
          <AccordionContent id="two">
            Michael Ulan Dakeev is an Associate Professor in Engineering
            Technology at Sam Houston State University. He holds a PhD in
            Technology and an MS in Industrial Management from the University of
            Northern Iowa. Michael is an NCCER Certified Master Trainer and
            Instructor for 21 crafts, and a TEDx speaker. He has over 58
            citations for his research publications and a design certification
            from MIT. His career includes roles at the University of
            Michigan-Flint and Texas A&M University-Kingsville, as well as
            industry experience with John Deere. Recognized for his excellence
            in teaching and innovation, Michael continues to lead in STEM
            education and technology advancements.
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccordionWrap;
