export const columns = [
  { id: "id", label: "#", minWidth: 30 },
  { id: "name", label: "Full Name", minWidth: 170 },
  { id: "code", label: "Country", minWidth: 130 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "grade",
    label: "Grade",
    minWidth: 20,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "school",
    label: "School Name",
    minWidth: 140,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "dob",
    label: "DOB",
    minWidth: 100,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 110,
    align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "registered",
    label: "Registered Exams",
    minWidth: 100,
    align: "right",
  },
];
