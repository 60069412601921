import React from "react";
import { useParams } from "react-router-dom";
import SEO from "../components/SEO.jsx";
import Header from "../partials/header/Header.jsx";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb.js";
import Footer from "../container/Footer/Footer.js";
import WorkData from "../data/work/workDetails.json";
import RobotixContainer from "../container/Work/RobotixContainer.js";
import ScrollToTop from "../components/ScrollToTop.jsx";
import NavScrollTop from "../components/NavScrollTop.jsx";
const Robotix = () => {
  //   let { id } = useParams();
  //   const workId = parseInt(id, 10);
  //   const data = WorkData.filter((work) => work.id === workId);
  return (
    <React.Fragment>
      <NavScrollTop>
        <SEO title="xCel || Robotics" />
        <Header />
        <Breadcrumb
          image="images/bg/robotix.png"
          title="Robotics"
          content="Home"
          contentTwo="Robotics"
        />
        <RobotixContainer />
        <Footer />
      </NavScrollTop>
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Robotix;
