import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function ScrollDialog({ handleCloseModal, openModal }) {
  return (
    <React.Fragment>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent>
          <DialogContentText>
            <p style={{ fontWeight: "bold" }}>
              CSV/EXCEL file student registration requirements:
            </p>
            <ul>
              <li>Emal - must be unique email per student</li>
              <li>firstName</li>
              <li>lastName</li>
              <li>Gender- Male or Female</li>
              <li>DOB- Date of Birth</li>
              <li>Phone</li>
              <li>CountryCode</li>
              <li>Country Name - agent code</li>
              <li>
                Supervisor/Coordinator id - should be in the id of a particular
                coordinator. each coordinator id will be shown on their
                dashboard
              </li>
              <li>schoolName</li>
              <li>schoolCity</li>
              <li>Grade - 3-12</li>
              <li>Password - at least five digits</li>
              <li>
                examIds - Can have multiple values separated by semicolon(;) Ex:
                EID00001 or EID00001;EID00002{" "}
              </li>
              <li>Code - optional, if exists</li>
              <li>TeamName - should be same for students in same team</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
