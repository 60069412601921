import React, { useEffect } from "react";
import AOS from "aos";
const GoogleMap = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="google-map-area section text-center section-padding-bottom">
      <div className="container">
        <div className="contact-map-area" data-aos="fade-up">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3336.6997189904164!2d-96.79932542560906!3d33.248169359761455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c3f7ec45444e7%3A0x3612009ccfcb5d4d!2s422%20Prosper%20Trail%2C%20Prosper%2C%20TX%2075078!5e0!3m2!1sen!2sus!4v1716150679480!5m2!1sen!2sus"
            width="600"
            height="450"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleMap;
