import * as React from "react";
import { isMobile } from "react-device-detect";
import WorldMap from "react-svg-worldmap";
import SectionTitle from "../components/SectionTitles/SectionTitle";
export default function CountryMap() {
  const data = [
    { country: "kg", value: 10 },
    { country: "tz", value: 10 },
    { country: "xk", value: 10 },
    { country: "bg", value: 10 },
    { country: "ro", value: 10 },
    { country: "mk", value: 10 },
    { country: "al", value: 10 },
    { country: "ao", value: 10 },
    { country: "us", value: 10 },
    { country: "az", value: 10 },
    { country: "pk", value: 10 },
    { country: "ge", value: 10 },
    { country: "ng", value: 10 },
    { country: "tm", value: 10 },
    { country: "uz", value: 10 },
    { country: "kz", value: 10 },
    { country: "tr", value: 6 },
    { country: "ke", value: 10 },
    { country: "mn", value: 6 },
    { country: "id", value: 6 },
    { country: "my", value: 6 },
    { country: "ua", value: 6 },
    { country: "kh", value: 6 },
    { country: "ca", value: 6 },
    { country: "vn", value: 6 },
    { country: "pg", value: 6 },
    { country: "dz", value: 6 },
  ];

  return (
    <div className="cta-section section">
      <div className="container text-center icon-up-down-animation">
        <SectionTitle
          headingOption="fz-32"
          title="XCEL's global presence now spans more countries"
          subTitle=""
        />
        <div>
          <WorldMap
            color="green"
            // title="XCEL's exclusive partners"
            value-suffix="people"
            size={!isMobile ? 950 : "md"}
            data={data}
            tooltipTextFunction={(countryName, isoCode, value) => {
              return `${countryName.countryName}`;
            }} // Show only country name
            styleFunction={(context) => {
              // Custom styles for countries based on their value
              const { countryValue } = context;
              if (countryValue > 7) {
                return { fill: "green", stroke: "black", strokeWidth: 3.5 };
              } else if (countryValue > 5) {
                return { fill: "#90EE90", stroke: "black", strokeWidth: 3.5 };
              } else {
                return { fill: "#d0f4f6" };
              }
            }}
          />
        </div>
        {/* <div style={styles.legendContainer}>
          <div style={styles.legendItem}>
            <span
              style={{ ...styles.legendColor, backgroundColor: "green" }}
            ></span>
            <span>Official partners</span>
          </div>
          <div style={styles.legendItem}>
            <span
              style={{ ...styles.legendColor, backgroundColor: "#90EE90" }}
            ></span>
            <span>In progress</span>
          </div>
        </div> */}
      </div>
    </div>
  );
}
