import { useState } from "react";
import MathProctorTestComponent from "../ProctorComponents/proctor";

import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import "./math.css";
import { isMobile } from "react-device-detect";

function PracticeCogatComponent() {
  const [startMathTest, setStartMathTest] = useState(false);
  const [studentMetaData, setStudentData] = useState([]);
  const handleMathStartTest = () => setStartMathTest(!startMathTest);

  const localStorageHasPracticeData =
    JSON.parse(localStorage.getItem("practiceCogat")) || {};
  const isNotEmpty = (obj) => Object.keys(obj).length > 0;
  return (
    <div
      style={{
        display: "flex",
        overflowY: "hidden",
        overflowX: "auto",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!startMathTest && (
        <>
          <h5 style={{ padding: "10px", textAlign: "center" }}>
            This is a Practice CogAT test
          </h5>
          <div
            style={{ marginTop: "20px", width: isMobile ? "90%" : "60%" }}
            className="scrollable-list"
          >
            <ol>
              <li>
                {" "}
                <strong>
                  This test platform works best with browsers such as Chrome,
                  Safari, and Edge
                </strong>{" "}
              </li>
              <li>
                Welcome to practice test! Take your time and don’t worry about
                time limits or question difficulty. This test is designed to
                help you review and reinforce your knowledge at your own pace.
              </li>
              <li>Feel free to retake as many times as you want.</li>
              <li>
                Get comfortable, so you’ll feel prepared when it’s time for the
                real test!
              </li>
              <li>
                <strong>
                  {" "}
                  Please remember to turn on your camera, microphone, and screen
                  share for the duration of the test.
                </strong>
              </li>
            </ol>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                margin: "10px",
              }}
            >
              <Button
                // style={{ marginBottom: "30px" }}
                variant="contained"
                onClick={handleMathStartTest}
              >
                <PlayArrowIcon style={{ margin: "0 7px" }} />
                {isNotEmpty(localStorageHasPracticeData)
                  ? "resume practice cogat test"
                  : "start practice cogat test"}
              </Button>
            </div>
          </div>
        </>
      )}
      {startMathTest && (
        <MathProctorTestComponent studentMetaData={studentMetaData} />
      )}
    </div>
  );
}

export default PracticeCogatComponent;
