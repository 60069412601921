import React from "react";
import { useParams } from "react-router-dom";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import BlogClassicData from "../data/blog/BlogClassic.json";
import BlogDetailsContainer from "../container/BlogGrid/BlogDetailsContainer";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import NavScrollTop from "../components/NavScrollTop.jsx";
import ScrollToTop from "../components/ScrollToTop.jsx";

const BlogDetails = () => {
  let { id } = useParams();
  const blogId = parseInt(id, 10);
  const data = BlogClassicData.filter((blog) => blog.id === blogId);
  return (
    <React.Fragment>
      <NavScrollTop>
        <SEO title="XCEL || Resources" />
        <Header />
        <Breadcrumb
          image="images/bg/breadcrumb-bg-four.jpg"
          title={data[0]?.title.slice(0, 31) + "..."}
          content="Home"
          contentTwo={data[0]?.categories[0]}
        />
        <BlogDetailsContainer data={data[0]} />
        {/* <CallToAction /> */}
        {/* <Footer /> */}
        <ScrollToTop />
      </NavScrollTop>
    </React.Fragment>
  );
};

export default BlogDetails;
