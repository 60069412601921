import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Question from "./Question";
// import questions from "../data/questions.json";
import axios from "axios";
import { envData } from "../../envs";
import Button from "@mui/material/Button";
import ExamFinishModal from "./examFinishModal";

const MathTest = ({ studentMetaData }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [finalResult, setFinalResult] = useState("");
  const [restartQuiz, setShowRestartQuizButton] = useState(false);
  const initialTime = 300; // 5 minutes in seconds

  const location = useLocation();

  const isMathRoute =
    location.pathname === "/dash/practiceMath" ||
    location.pathname === "/dash/practiceCogat";
  const localStorageTestType = () => {
    if (location.pathname === "/dash/math") {
      return "mathTest";
    } else if (location.pathname === "/dash/cogat") {
      return "cogatTest";
    } else if (location.pathname === "/dash/practiceMath") {
      return "practiceMath";
    } else if (location.pathname === "/dash/practiceCogat") {
      return "practiceCogat";
    }
  };
  const [timeLeft, setTimeLeft] = useState(
    parseInt(localStorage.getItem(`${localStorageTestType()}Timer`)) ||
      initialTime
  );
  // Initialize answers from localStorage or set to empty object
  const [answers, setAnswers] = useState(() => {
    return JSON.parse(localStorage.getItem(localStorageTestType())) || {};
  });

  const currentQuestion = questions && questions[currentQuestionIndex];
  const getMathQuestions = async () => {
    const token = localStorage.getItem("token");
    try {
      const mathRequest = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/mock/math`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const cogatRequest = await axios.get(
        `${envData.baseApiUrl}/v1/students/student/mock/cogat`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const [mathResponse, cogatResponse] = await Promise.all([
        mathRequest,
        cogatRequest,
      ]);

      // const isEmpty = (obj) => Object.keys(obj).length === 0;
      if (location.pathname === "/dash/practiceMath") {
        setQuestions(mathResponse.data);
      }
      if (location.pathname === "/dash/practiceCogat") {
        setQuestions(cogatResponse.data);
      }

      setLoading(true);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    // console.log(newData);
    if (isMathRoute) {
      getMathQuestions();

      return;
    }
    const mathQuestions = studentMetaData.exams.filter(
      (exam) => exam.examName === "Math"
    );
    setQuestions(
      mathQuestions[0]?.questions?.length ? mathQuestions[0]?.questions : []
    );
    setLoading(true);
  }, []);
  // Save answers to localStorage on every answer change
  useEffect(() => {
    localStorage.setItem(localStorageTestType(), JSON.stringify(answers));
  }, [answers]);
  const handleAnswerChange = (blob_id, answer) => {
    const updatedAnswers = { ...answers, [blob_id]: answer };
    setAnswers(updatedAnswers);
  };
  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(`${localStorageTestType()}Timer`, newTime); // Save remaining time to localStorage
          return newTime;
        });
      }, 1000);
    } else if (timeLeft === 0) {
      calculateResults(); // Auto-submit when time runs out
      localStorage.removeItem(`${localStorageTestType()}Timer`); // Clear time from localStorage
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateResults = () => {
    let score = 0;
    questions.forEach((question) => {
      if (
        answers[question.blob_id] &&
        answers[question.blob_id] === question.answer
      ) {
        score += 1;
      }
    });
    setOpenModal(true);
    setShowRestartQuizButton(true);
    setFinalResult(`Your score is: ${score} out of ${questions.length}`);

    // alert(`Quiz finished! Your score is: ${score} out of ${questions.length}`);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleRestartPracticeQuiz = () => {
    setCurrentQuestionIndex(0);
    setShowRestartQuizButton(false);
    setTimeLeft(initialTime);
    localStorage.removeItem(`${localStorageTestType()}Timer`);
  };

  return (
    <>
      {loading ? (
        questions.length ? (
          <div style={{ paddingLeft: "10px", marginLeft: "20px" }}>
            <div
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
              }}
            >
              <h5>
                Question {currentQuestionIndex + 1} of {questions.length}
              </h5>
              <div>Time Left: {formatTime(timeLeft)}</div>
            </div>
            <Question
              question={currentQuestion}
              userAnswer={answers[currentQuestion.blob_id] || ""} // Use stored answer if available
              onAnswerChange={handleAnswerChange}
              localStorageTestType={localStorageTestType}
            />
            <div style={{ paddingLeft: "50px", marginLeft: "30px" }}>
              <Button
                variant="contained"
                style={{ color: "white", marginRight: "4px" }}
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
              {currentQuestionIndex < questions.length - 1 && !restartQuiz ? (
                <Button
                  style={{
                    backgroundColor: "#343579",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    color: "white",
                  }}
                  onClick={handleNext}
                >
                  Next
                </Button>
              ) : (
                <>
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "teal",
                      marginRight: "4px",
                    }}
                    onClick={calculateResults}
                  >
                    Finish Quiz
                  </Button>
                  {restartQuiz && isMathRoute && (
                    <Button
                      variant="outlined"
                      // style={{ color: "white", backgroundColor: "teal" }}
                      onClick={handleRestartPracticeQuiz}
                    >
                      Restart Quiz
                    </Button>
                  )}
                </>
              )}
            </div>
            <ExamFinishModal
              finalResult={finalResult}
              handleCloseModal={handleCloseModal}
              openModal={openModal}
            />
          </div>
        ) : (
          <>
            {" "}
            <h5>There are no questions to show at this moment!</h5>{" "}
          </>
        )
      ) : (
        "loading"
      )}
    </>
  );
};

export default MathTest;
