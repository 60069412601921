import React from "react";
import { useParams } from "react-router-dom";
import SEO from "../components/SEO.jsx";
import Header from "../partials/header/Header.jsx";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb.js";
import Footer from "../container/Footer/Footer.js";
import WorkData from "../data/work/workDetails.json";
import MathContainer from "../container/Work/MathContainer.js";
import ScrollToTop from "../components/ScrollToTop.jsx";
import NavScrollTop from "../components/NavScrollTop.jsx";

const Math = () => {
  //   let { id } = useParams();
  //   const workId = parseInt(id, 10);
  //   const data = WorkData.filter((work) => work.id === workId);
  return (
    <React.Fragment>
      <NavScrollTop>
        <SEO title="xCel || Math" />
        <Header />
        <Breadcrumb
          image="images/bg/math.png"
          title="Math"
          content="Home"
          contentTwo="Math"
        />
        <MathContainer />
        <Footer />
        <ScrollToTop />
      </NavScrollTop>
    </React.Fragment>
  );
};

export default Math;
