import * as React from "react";

import { styled } from "@mui/material/styles";
import geo4 from "./geo4.pdf";
import logic4 from "./logic4.pdf";
import cogat2 from "./cogat2.pdf";
import { examData } from "./examData";
import "./ExamCard.scss";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
export default function ExamSubjectCard({ exam, subjectResource }) {
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  return (
    <div
      className="col-sm-4 col-12 mb-2"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="cards">
        <article
          className="plan [ card ]"
          style={{ height: "450px", position: "relative" }}
        >
          <div className="inner" style={{ height: "450px" }}>
            <span className="pricing">
              <span>
                Code <small>/ {exam.examCode}</small>
              </span>
            </span>
            <h2 className="title">{exam.examName}</h2>
            {/* <p className="info">{subjectResource.description}</p> */}
            <h3 className="title">resources:</h3>
            <ul className="features" style={{ padding: "3px" }}>
              {subjectResource.resources.map((resource, ind) => {
                return (
                  <li style={{ padding: "0px" }} key={ind}>
                    <a
                      href={resource.pdfLocation}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      <span
                        style={{
                          marginLeft: "3px",
                          textDecoration: "underline",
                        }}
                      >
                        {resource.description} {resource.icon}{" "}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
            <button
              disabled
              className="button"
              style={{
                color: "lightgrey",
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            >
              Start exam
            </button>
          </div>
        </article>
      </div>
      {/* <Card sx={{ fontSize: "2rem", maxWidth: 345, borderRadius: "5%", boxShadow: "2px 2px 15x 10px grey", color: "Black" }} >
                <CardHeader


                    sx={{ fontWeight: "bold", fontSize: "2rem" }}
                    title='Your test'
                // subheader="September 14, 2016"
                />
                <CardMedia
                    component="img"
                    sx={{ height: 80, width: 80, margin: 'auto', fontWeight: "bold" }}
                    image='https://png.pngtree.com/element_pic/00/16/09/1457d88684dfe93.png'
                    alt="title"
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary" style={{ color: 'black' }}>
                        {` test assesses your critical thinking and...`}
                    </Typography>
                </CardContent>
                <CardContent>
                    <Grid container >
                        <Demo>
                            <List dense={dense} >
                                {generate(
                                    <ListItem style={{ padding: '0' }}>
                                        <ListItemIcon>
                                            <FolderIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary="Test Bank"
                                            secondary={secondary ? 'Secondary text' : null}
                                        />
                                    </ListItem>,
                                )}
                            </List>
                        </Demo>

                    </Grid>
                </CardContent>


            </Card> */}
    </div>
  );
}
