import React, { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { isMobile } from "react-device-detect";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { fakeAuthProvider } from "../../LoginActions";
import { envData } from "../../envs";

const stripePromise = loadStripe(envData.stripePublicKey);

export default function PayMethod() {
  const getAddedExamsFromSession =
    sessionStorage.getItem("exams") &&
    JSON.parse(sessionStorage.getItem("exams"));
  const tokenFromLocal = fakeAuthProvider.getToken();
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    return await fetch(`${envData.baseApiUrl}/v1/pmt/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenFromLocal,
      },
      body: JSON.stringify({
        email: fakeAuthProvider.getData().email,
        exam: getAddedExamsFromSession.map((id) => id.id),
      }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div style={{ padding: isMobile ? "5px" : "40px" }}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
