import React from "react";
import { FaUserAlt } from "react-icons/fa";

import { Link } from "react-router-dom";

const HeaderSearch = () => {
  return (
    <div className="header-search">
      <div className="header-search-area ms-xl-7 ms-0">
        {
          <Link
            to={process.env.PUBLIC_URL + "/dash"}
            className="btn btn-primary btn-hover-secondary"
          >
            <FaUserAlt />
            <span style={{ margin: "2px" }}></span>
            my profile
          </Link>
        }
      </div>
    </div>
  );
};

export default HeaderSearch;
