import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fakeAuthProvider } from "../../LoginActions";
import "./intro.css";
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};
const Intro = ({ data }) => {
  const { width } = useScreenSize();

  const isMobile = width <= 950;
  return (
    <>
      {isMobile ? (
        <div className="landing-page">
          <div className="content">
            <div className="programs">
              <img
                // height="300px"
                src={process.env.PUBLIC_URL + data.backgroundImage}
                alt="Programs"
                // style={{ height: "300px" }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="intro-section overlay section"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + data.backgroundImage
            })`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="container">
            <div className="row row-cols-lg-1 row-cols-1">
              <div className="col align-self-center">
                <div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                  <h2 className="title">{data.title}</h2>
                  <div className="desc">
                    <p>{data.desc}</p>
                  </div>
                  {/* {fakeAuthProvider.isExprired() && data.id !== "1" && (
                    <Link
                      to={process.env.PUBLIC_URL + "/dash"}
                      className="btn btn-primary btn-hover-secondary"
                    >
                      Register Now
                    </Link>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro;
