import React, { useState, useEffect } from "react";
import axios from "axios";
import { envData } from "../../envs";

const PaymentStatusPage = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("token");

  const fetchData = async () => {
    try {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const response = await axios.get(
        `${envData.baseApiUrl}/v1/pmt/verify-payment`,
        {
          params: { session_id: params.get("session_id") },
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setData(response.data);
      sessionStorage.removeItem("exams");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      style={{
        color: "white",
        fontSize: "1.2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontWeight: "bold",
      }}
    >
      <div style={{ marginTop: "20px", color: "#52668C" }}>
        Your Payment Status
      </div>
      <h4 style={{ color: "green" }}>{data?.payment_status}</h4>
    </div>
  );
};

export default PaymentStatusPage;
