import { useState, useEffect, useRef } from "react";
import AOS from "aos";
import SectionTitle from "../SectionTitles/SectionTitle";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();
    AOS.init();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div
      className="section section-padding-t90 section-padding-bottom-200"
      data-aos="fade-up"
    >
      <div className="container">
        <SectionTitle
          title="We empower you to embrace challenges and grow stronger."
          subTitle=""
        />

        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/about/home-one-about/art.jpg"
                    }
                    alt="art"
                  />
                </Tilt>
              </div>

              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/about/home-one-about/cogat.jpg"
                    }
                    alt="cogat"
                  />
                </Tilt>
              </div>
              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/shape-animation/about-shape-1.png"
                    }
                    alt="about"
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 col-12" data-aos-delay="300">
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle="Every day brings new challenges"
                title="xCel focused on vision, quality and success"
              />

              <p>
                You are boldly individual, always original, and refreshingly
                easy-going. Your vision, passion, and ideas are matched with
                focus, expertise, and flair
              </p>

              <Link
                className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4"
                to={process.env.PUBLIC_URL + "/about"}
              >
                About Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
