import React, { useEffect, useState } from "react";
import axios from "axios";
import { envData } from "../../envs";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function RoboticsRegistration() {
  const [studentData, setStudentData] = useState("");
  const [files, setFiles] = useState([]);
  const [msg, setMsg] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [open, setOpen] = useState(false);
  const [teamInfo, setTeamInfo] = useState("");
  const [teamName, setTeamName] = useState("");
  const [existingVideoLink, setExistingVideoLinkUpdate] = useState(true);
  const [videoLinkUploaded, setVideoLinkUploaded] = useState(false);
  const [teamUpdated, setTeamUpdated] = useState(false);
  const [roboticsCategory, setRoboticsCategory] = useState("");
  const handleCategoryChange = (event) => {
    setRoboticsCategory(event.target.value);
  };
  const handleTeamName = (e) => {
    setTeamName(e.target.value);
  };
  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };
  const getStudentInfo = async () => {
    const token = localStorage.getItem("token");
    try {
      const [request1, request2] = await Promise.all([
        axios.get(`${envData.baseApiUrl}/v1/students/student/team/list`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${envData.baseApiUrl}/v1/students`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setTeamInfo(request1.data);
      if (request1.data?.msg?.url) {
        setExistingVideoLinkUpdate(false);
      }
      setStudentData(request2.data);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleDataSubmit = async () => {
    if (!teamName) {
      alert("Please enter your team name");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student`,
        {
          type: "update",
          teamName: teamName.trim().toLocaleLowerCase(),
          country: "C00002",
          countryName: studentData.countryName,
          dob: studentData.dob,
          firstName: studentData.firstName,
          lastName: studentData.lastName,
          phone: studentData.phone,
          gender: studentData.gender,
          schoolName: studentData.schoolName,
          schoolCity: studentData.schoolCity,
          grade: studentData.grade,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setTeamUpdated(true);
        setOpen(true);
        setVideoLink("");
        setMsg("Team name has been submitted");
      }
    } catch (error) {
      console.error("Error posting to APIs:", error);
    }
  };
  const handleFileSubmit = async (event) => {
    event.preventDefault();
    if (!files) {
      alert("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("type", "upload");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student/team/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setOpen(true);
        setMsg("File has been uploaded");
        setFiles([]);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setMsg(error);
    }
  };
  const handleVideLinkChange = (e) => {
    setVideoLink(e.target.value);
  };
  const submitVideoLink = async (event) => {
    event.preventDefault();
    if (!studentData.teamName && !teamUpdated) {
      alert("Save your team name first");
      return;
    }
    if (!videoLink) {
      alert("Please enter your video link to upload");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${envData.baseApiUrl}/v1/students/student/team/update`,
        { url: videoLink, category: roboticsCategory },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setVideoLinkUploaded(!videoLinkUploaded);
        setOpen(true);
        setVideoLink("");
        setMsg("Link has been submitted");
      }
    } catch (error) {
      console.error("Error uploading link:", error);
      setMsg(error);
    }
  };
  const handleExistingVideoLinkUpdate = () => {
    setExistingVideoLinkUpdate(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getStudentInfo();
  }, [teamUpdated, videoLinkUploaded]);
  return (
    <>
      <div
        style={{
          //   display: "flex",
          //   alignItems: "center",
          padding: "20px",
          background: "#001F3F",
          margin: "10px auto",
          width: "90%",
          justifyContent: "space-around",
          flexDirection: isMobile ? "column" : "",
        }}
      >
        <div>
          <a
            rel="noreferrer"
            style={{
              fontSize: "20px",
              color: "#F99F7F",
              marginBottom: "5px",
              textDecoration: "underline",
            }}
            target="_blank"
            href="https://www.youtube.com/watch?v=jaftEW9WI3U"
          >
            Watch how to create your own YOUTUBE Link
          </a>
          <h6 style={{ color: "white" }}>Team members:</h6>
          <ul>
            {teamInfo &&
              Array.isArray(teamInfo.msg.members) &&
              teamInfo.msg.members.map((i, ind) => {
                return <li key={ind}>{i.firstName + " " + i.lastName}</li>;
              })}
          </ul>
          <h6 style={{ color: "white" }}>
            Team name:{" "}
            <span style={{ fontWeight: "normal" }}>
              {studentData.teamName && studentData?.teamName?.toUpperCase()}
            </span>{" "}
          </h6>
          <h6 style={{ color: "white" }}>
            YouTube Video link:
            {teamInfo?.msg?.url && (
              <>
                <a
                  href={teamInfo?.msg?.url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontWeight: "normal", marginLeft: "3px" }}
                >
                  <SlideshowIcon />
                </a>
                <Button onClick={handleExistingVideoLinkUpdate}>
                  Update link
                </Button>
              </>
            )}
          </h6>

          <div style={{ marginTop: "10px" }}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              style={{ padding: "7px 24px" }}
            >
              Upload file
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            <br />
            <Typography style={{ height: "23px" }}>
              {files["0"]?.name &&
                files["0"]?.name.slice(0, 7) +
                  "." +
                  files["0"]?.name.split(".").pop()}
            </Typography>
          </div>
          <Button
            style={{ padding: "7px 27px" }}
            type="submit"
            variant="contained"
            color="success"
            onClick={handleFileSubmit}
          >
            <CheckCircleOutlineIcon />
            Submit file
          </Button>
        </div>
      </div>
      <div style={{ width: "90%", margin: "10px auto" }}>
        {!studentData.teamName && (
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 350,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter your team name"
              inputProps={{ "aria-label": "teamName" }}
              onChange={handleTeamName}
              value={teamName}
            />
            <Button
              onClick={handleDataSubmit}
              style={{ margin: "3px", padding: "5px 7px" }}
              variant="contained"
            >
              Save Team
            </Button>
          </Paper>
        )}
        <Divider sx={{ height: 5, m: 0.5 }} orientation="horizontal" />
        {existingVideoLink && (
          <FormControl sx={{ minWidth: 350 }} size="small">
            <InputLabel id="demo-select-small-label">
              Select robot category
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={roboticsCategory}
              label="Category"
              onChange={handleCategoryChange}
              sx={{ background: "white" }}
            >
              <MenuItem value="robot design">Robot Design</MenuItem>
              <MenuItem value="line follower">Line Follower</MenuItem>
              <MenuItem value="payload carrier">Payload Carrier</MenuItem>
            </Select>
          </FormControl>
        )}
        <Divider sx={{ height: 5, m: 0.5 }} orientation="horizontal" />
        {existingVideoLink && (
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 350,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter your video link"
              inputProps={{ "aria-label": "video link" }}
              onChange={handleVideLinkChange}
              value={videoLink}
            />

            <Button
              onClick={submitVideoLink}
              style={{ margin: "3px", padding: "5px" }}
              variant="contained"
            >
              Save video
            </Button>
          </Paper>
        )}
        <Divider sx={{ height: 5, m: 0.5 }} orientation="horizontal" />
      </div>
      <div style={{ textAlign: "center" }}>
        <Snackbar
          key={"center" + "center"}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {msg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default RoboticsRegistration;
