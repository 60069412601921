import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { fakeAuthProvider } from "../../../LoginActions";

export function AccountInfo({ userProfileData }) {
  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <div>
            <Avatar
              src={
                userProfileData.data.gender === "male"
                  ? "https://d1nhio0ox7pgb.cloudfront.net/_img/v_collection_png/512x512/shadow/teacher.png"
                  : "https://cdn-icons-png.flaticon.com/512/206/206897.png"
              }
              sx={{ height: "80px", width: "80px" }}
            />
          </div>
          <Stack spacing={1} sx={{ textAlign: "center" }}>
            <Typography variant="h5">
              {fakeAuthProvider.getData().firstname +
                " " +
                fakeAuthProvider.getData().lastname}
            </Typography>
            {/* <Typography color="text.secondary" variant="body2">
              {userProfileData.data.schoolCity +
                ", " +
                userProfileData.data.schoolName}
            </Typography> */}
            {(fakeAuthProvider.getData().type === "I" ||
              fakeAuthProvider.getData().type === "P") && (
              <Typography color="text.primary" variant="body1">
                Supervisor/Teacher <br /> Unique ID:
                {fakeAuthProvider.getData().agent}
              </Typography>
            )}
            {/* <Typography color="text.secondary" variant="body2">
              {user.timezone}
            </Typography> */}
          </Stack>
        </Stack>
      </CardContent>
      <Divider />
      {/* <CardActions>
        <Button fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions> */}
    </Card>
  );
}
